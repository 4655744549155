import React from "react";
const classText = {
  header2: "text-[20px] md:text-[20px]	lg:text-[24px]", // 26
  header: "text-[16px] md:text-[16px]	lg:text-[20px]", // 16
  title: "text-[16px] md:text-[16px]	lg:text-[18px]", //14
  body: "text-[14px] md:text-[14px]	lg:text-[16px]", //12
  content: "text-[10px] md:text-[12px]	lg:text-[14px]", //10
  small: "text-[14px] md:text-[10px]	lg:text-[12px]", //8
};
export const textLevel = {
  header: "header",
  title: "title",
  body: "body",
  content: "content",
  small: "small",
  header2: "header2",
};

export default function TextBase({
  children,
  type = textLevel.body,
  className,
}) {
  return <div className={`${classText[type]} ${className}`}>{children}</div>;
}
