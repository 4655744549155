import React from "react";
import BaseImage from "../components/baseImage/BaseImage";
import TextBase from "../components/text/TextBase";
import { textLevel } from "../components/text/TextBase";

export default function Index() {
  return (
    <div className="flex flex-row justify-center pt-20 ">
      <div className="sm:pt-12">
        <div className="flex flex-col px-3 sm:px-10">
          <p className="text-gray-600 text-title-2 md:text-title-1">
            Page not available
          </p>
          <p className={"text-base text-left text-gray-500 "}>
            الصفحة غير متاحة
          </p>
        </div>
      </div>
      <div className="w-[85px] h-[77px] sm:w-[256px] sm:h-[232px]">
        <BaseImage src={"/images/error_404.png"} />
      </div>
    </div>
  );
}
